import Intro from './components/intro/Intro';
import About from './components/about/About';
import ProductList from './components/productList/ProductList';
import Contact from './components/contact/Contact';
import Portfolio from './components/portfolio/Portfolio';

function App() {
  return (
    <div>
      <Intro />
      <About />
      <Portfolio />
      <ProductList />
      <Contact />
    </div>
  );
}

export default App;
