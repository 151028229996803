import React, { useEffect, useRef } from 'react';
import './about.css';
import Photo from '../../img/about-photo.png';
import { skills } from './skills';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const aboutRef = useRef(null);
    const imageRef = useRef(null);
    const titleRef = useRef(null);
    const subRef = useRef(null);
    const descRef = useRef(null);
    const languagesRef = useRef(null);
    const skillsRefs = useRef([]);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: aboutRef.current,
                start: "top 80%",
            }
        });

        tl.from(imageRef.current, { duration: 1, y: 50, opacity: 0 })
          .from([titleRef.current], { duration: 0.5, y: 50, opacity: 0 }, "-=0.5")
          .from([subRef.current, descRef.current], { duration: 0.5, y: 50, opacity: 0 }, "-=0.5")

        gsap.from(languagesRef.current, {
            duration: 1,
            y: 50,
            opacity: 0,
            scrollTrigger: {
                trigger: languagesRef.current,
                start: "top 80%",
            }
        });

        skillsRefs.current.forEach((skill, index) => {
            gsap.from(skill, {
                duration: 0.5,
                y: 50,
                opacity: 0,
                delay: index * 0.2,
                scrollTrigger: {
                    trigger: skill,
                    start: "top 90%",
                }
            });
        });
    }, []);

    return (
        <div className='about' ref={aboutRef}>
            <div className="about-left">
                <img src={Photo} alt="" ref={imageRef} />
            </div>
            <div className="about-right">
                <h1 className="about-title" ref={titleRef}>About Me</h1>
                <p className="about-sub" ref={subRef}>
                    Who am I?
                </p>
                <p className="about-desc" ref={descRef}>
                    My name is Manuela, I'm 29 years old. I love languages and I consider myself a polyglot since I speak Portuguese, Spanish, English, Javascript and React. I am a passionate developer based in Barcelona working on the Front-End side using mainly React. Since I discovered the programming world I have not been able to spend a single day without writing a line of code. It's easy for me to spend my time doing it, since it mixes both of my passions: technology and creativity.
                </p>
                <div>
                    <h2 className='h2' ref={languagesRef}>Languages and frameworks</h2>
                    <div className="skill-grid">
                        {skills.map((skill, index) => (
                            <div
                                className="skill-card"
                                key={skill.name}
                                ref={el => skillsRefs.current[index] = el}
                            >
                                <img src={skill.img} alt={skill.name} />
                                <p>{skill.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
