import React, { useEffect, useRef } from 'react';
import './intro.css';
import me from '../../img/me.png';
import git from '../../img/github.png';
import linkedin from '../../img/linkedin.png';
import cv from '../intro/cv.pdf';
import { gsap } from 'gsap';

const Intro = () => {
    const introLeftRef = useRef(null);
    const nameRef = useRef(null);
    const titleRef = useRef(null);
    const descRef = useRef(null);
    const logoRef = useRef(null);
    const resumeRef = useRef(null);
    const introRightRef = useRef(null);


    useEffect(() => {
        const tl = gsap.timeline();

        tl.from(introLeftRef.current, { duration: 1, y: -50, opacity: 0 })
          .from([nameRef.current, titleRef.current], { duration: 1, opacity: 0 }, "-=0.8")
          .from(descRef.current, { duration: 1, y: -50, opacity: 0 }, "-=0.8")
          .from([logoRef.current, resumeRef.current], { duration: 1, y: -50, opacity: 0 }, "-=0.8");


        tl.from(introRightRef.current, { duration: 1.5, y: -50, opacity: 0 }, 0.2);
    }, []);

    return (
        <div className='intro'>
            <div className="intro-left" ref={introLeftRef}>
                <div className="intro-left-wrapper">
                    <div className="photoProfile">
                        <img src={require('../../img/myself.png')} alt="me" className='mobile' />
                    </div>
                    <h2 className='introduction'>Hello, my name is</h2>
                    <h1 className='intro-name' ref={nameRef}>Manuela Chamoso</h1>
                    <div className="intro-title" ref={titleRef}>
                        <div className="intro-title-wrapper">
                            <div className="intro-title-item">Front-end Developer</div>
                            <div className="intro-title-item">UX/UI Designer</div>
                            <div className="intro-title-item">Web Developer</div>
                            <div className="intro-title-item">Content Creator</div>
                            <div className="intro-title-item">Web Designer</div>
                            <div className="intro-title-item">Enthusiastic Dev</div>
                        </div>
                    </div>
                    <p className="intro-desc" ref={descRef}>
                        I am a passionate and enthusiastic web developer, eager for new challenges and offering my best on each new project. My main area of expertise is front-end development.
                    </p>
                    <div className='logo' ref={logoRef}>
                        <a href="https://github.com/manuelachamoso/">
                            <img src={git} alt="GitHub" />
                        </a>
                        <a href="https://www.linkedin.com/in/manuela-chamoso-cunha-55168776/">
                            <img src={linkedin} alt="LinkedIn" />
                        </a>   
                    </div>
                    <a href={cv} download='resume' target='_blank' rel="noreferrer" className='cv' ref={resumeRef}>
                        Download Resume
                        <svg xmlns="http://www.w3.org/2000/svg" className="download" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="intro-right" ref={introRightRef}>
                <div className="intro-bg"></div>
                <img src={me} alt="Manuela Chamoso" className='intro-img' />
            </div>
        </div>
    );
}

export default Intro;
