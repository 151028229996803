import React from 'react'
import './contact.css'

const Contact = () => {
    return (
        <div className='footer'>
            <div>
            <p>Copyright © 2022 Manuela Chamoso</p>
            </div>
        </div>
    )
}

export default Contact
