import React, { useEffect, useRef } from 'react';
import './productList.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ProductList = () => {
    const headerRef = useRef(null);
    const descRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: headerRef.current,
                start: "top 90%", 
            }
        });

        tl.from(headerRef.current, { duration: 1, y: 50, opacity: 0 })
          .from(descRef.current, { duration: 1, y: 50, opacity: 0 }, "-=0.5")
          .from(buttonRef.current, { duration: 1, y: 50, opacity: 0 }, "-=0.5");
    }, []);

    return (
        <div className='pl'>
            <div className="pl-texts">
                <h1 className='pl-title' ref={headerRef}>Create & inspire.</h1>
                <p className='pl-desc' ref={descRef}>
                    In my free time I love to read books, travel and discover new restaurants. I have a brother that studies at med school, and most people find it interesting that I, as a girl, decided to become a web developer. I try to use most of my free time to practice and work on my coding skills and I love doing projects with React and JavaScript.
                </p>
                <div className="c-right" ref={buttonRef}>
                    <a href="mailto:manuela.chamoso@gmail.com" className="button">
                        <button>Hire me</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ProductList;
