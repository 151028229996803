import React, { useEffect, useRef, useState } from 'react';
import './portfolio.css';
import PortfolioList from '../portfolioList/PortfolioList';
import { allPortfolio, javascriptPortfolio, reactPortfolio, gamesPortfolio, nextPortfolio, typescriptPortfolio } from '../../data';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {
    const [selected, setSelected] = useState('All');
    const [data, setData] = useState([]);
    const portfolioRef = useRef([]);
    const headerRef = useRef([]);
    const categoriesRef = useRef([]);
    const websiteRef = useRef([]);

    const list = [
        { id: "All", title: "All" },
        { id: "React", title: "React" },
        { id: "Next", title: "Next" },
        { id: "Typescript", title: "Typescript" },
        { id: "JavaScript", title: "JavaScript" },
        { id: "Games", title: "Games" },
    ];

    useEffect(() => {
        switch (selected) {
            case "All": setData(allPortfolio); break;
            case "React": setData(reactPortfolio); break;
            case "Next": setData(nextPortfolio); break;
            case "Typescript": setData(typescriptPortfolio); break;
            case "JavaScript": setData(javascriptPortfolio); break;
            case "Games": setData(gamesPortfolio); break;
            default: setData(allPortfolio);
        }
    }, [selected]);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: portfolioRef.current,
                start: "top center",
            }
        });

        tl.from(headerRef.current, { duration: 1, y: 50, opacity: 0 })
        .from(categoriesRef.current, { duration: 1, y: 50, opacity: 0 }, "-=0.5")
        .from(websiteRef.current, { duration: 1, y: 50, opacity: 0 }, "-=0.5")
    }, []);

    return (
        <div className='portfolio' id='portfolio' ref={portfolioRef}>
            <h1 ref={headerRef}>Portfolio</h1>
            <ul ref={categoriesRef}>
                {list.map(item => (
                    <PortfolioList
                        key={item.id}
                        title={item.title}
                        active={selected === item.id}
                        setSelected={setSelected}
                        id={item.id}
                    />
                ))}
            </ul>
            <div className="container" ref={websiteRef}>
                {data.map((d, index) => (
                    <div className='item' key={index}>
                        <a href={d.link} target='_blank' rel='noreferrer'>
                            <img src={d.img} alt={d.title} />
                        </a>
                        <h3>{d.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Portfolio;
