export const allPortfolio = [
    {
        id: 16,
        title: 'Company Dashboard',
        img: require('../src/img/devchamoso.png'),
        link: "https://company-dashboard-kappa.vercel.app/",
    },
    {
        id: 15,
        title: 'Acqua Bank',
        img: require('../src/img/acqua.png'),
        link: "https://acqua-bank.vercel.app/",
    },
    {
        id: 14,
        title: 'Apple Website',
        img: require('../src/img/apple.png'),
        link: "https://iphone-15-9t8sbp9ot-manuelachamosos-projects.vercel.app/",
    },
    {
        id: 11,
        title: 'Super Mario Word',
        img: require('../src/img/mario.png'),
        link: "https://mario-ashen.vercel.app/",
    },   
    {
        id: 1,
        title: "Goya Restaurant",
        img: require('../src/img/goya.png'),
        link: "https://en-goya.netlify.app/",
    },
    {
        id: 12,
        title: 'Capture Pro',
        img: require('../src/img/capturepro.png'),
        link: "https://capturepro.vercel.app/",
    },
    {
        id: 17,
        title: 'Banco Guia de Bolso',
        img: require('../src/img/guiabolso.png'),
        link: "https://guia-bolso.netlify.app/",
    },

    {
        id: 10,
        title: "Movie Booking Seat",
        img: 'https://i.ibb.co/hYZbnRR/seat-movie.png',
        link: "https://movie-booking-seat.netlify.app/",
    },
    {
        id: 2,
        title: 'Movie Streaming',
        img: 'https://i.ibb.co/bHNfPJq/movie-page.png',
        link: "https://verdant-raindrop-5eb617.netlify.app/",
    },
    {
        id: 3,
        title: 'Tic Tac Toe',
        img: 'https://i.ibb.co/qRKV8SR/tic-tac-toe.png',
        link: "https://voluble-griffin-b75059.netlify.app/",
    },
    {
        id: 4,
        title: 'Restaurant Delicious',
        img: 'https://i.ibb.co/j5LBYGZ/Delicious.png',
        link: "https://eatdelicious.netlify.app/",
    },
    {
        id: 5,
        title: 'Movies React',
        img: 'https://i.ibb.co/fG4dTQ3/movie-react.png',
        link: "https://moviestomates.netlify.app/",
    },
    {
        id: 6,
        title: 'Cryptocurrency',
        img: 'https://i.ibb.co/MkpSHKC/crypto.png',
        link: "https://cryptobroker.netlify.app/",
    },
    {
        id: 7,
        title: 'Restaurant Chiquito',
        img: 'https://i.ibb.co/MBDhBzZ/chiquito.png',
        link: "https://chiquito.netlify.app/",
    },
    {
        id: 8,
        title: 'Trivia Game',
        img: 'https://i.ibb.co/1vBqg1J/trivia.png',
        link: "https://trivial-quiz.netlify.app/",
    },
    {
        id: 9,
        title: 'Paper Rock Scissor',
        img: 'https://i.ibb.co/jHsK4fb/rock-paper-scissor.png',
        link: "https://stoic-hermann-5cc792.netlify.app/",
    },

    {
        id: 13,
        title: 'Best Shape',
        img: require('../src/img/bestshape.png'),
        link: "https://best-shape.vercel.app/",
    },
    {
        id: 18,
        title: 'Sounds Good',
        img: require('../src/img/soundsgood.png'),
        link: "https://innerbeat.netlify.app/",
    },
]

export const reactPortfolio = [
    {
        id: 14,
        title: 'Apple Website',
        img: require('../src/img/apple.png'),
        link: "https://iphone-15-9t8sbp9ot-manuelachamosos-projects.vercel.app/",
    },
    {
        id: 1,
        title: "Goya Restaurant",
        img: require('../src/img/goya.png'),
        link: "https://en-goya.netlify.app/",
    },
    {
        id: 5,
        title: 'Movies React',
        img: 'https://i.ibb.co/fG4dTQ3/movie-react.png',
        link: "https://moviestomates.netlify.app/",
    },
    {
        id: 8,
        title: 'Trivia Game',
        img: 'https://i.ibb.co/1vBqg1J/trivia.png',
        link: "https://trivial-quiz.netlify.app/",
    },
]

export const javascriptPortfolio = [
    {
        id: 13,
        title: 'Best Shape',
        img: require('../src/img/bestshape.png'),
        link: "https://best-shape.vercel.app/",
    },
    {
        id: 17,
        title: 'Banco Guia de Bolso',
        img: require('../src/img/guiabolso.png'),
        link: "https://guia-bolso.netlify.app/",
    },
    {
        id: 11,
        title: 'Super Mario Word',
        img: require('../src/img/mario.png'),
        link: "https://mario-ashen.vercel.app/",
    },
    {
        id: 12,
        title: 'Capture Pro',
        img: require('../src/img/capturepro.png'),
        link: "https://capturepro.vercel.app/",
    },
    {
        id: 10,
        title: "Movie Booking Seat",
        img: 'https://i.ibb.co/hYZbnRR/seat-movie.png',
        link: "https://movie-booking-seat.netlify.app/",
    },
    {
        id: 2,
        title: 'Movie Streaming',
        img: 'https://i.ibb.co/bHNfPJq/movie-page.png',
        link: "https://verdant-raindrop-5eb617.netlify.app/",
    },
    {
        id: 3,
        title: 'Tic Tac Toe',
        img: 'https://i.ibb.co/qRKV8SR/tic-tac-toe.png',
        link: "https://voluble-griffin-b75059.netlify.app/",
    },
    {
        id: 4,
        title: 'Restaurant Delicious',
        img: 'https://i.ibb.co/j5LBYGZ/Delicious.png',
        link: "https://eatdelicious.netlify.app/",
    },
    {
        id: 18,
        title: 'Sounds Good',
        img: require('../src/img/soundsgood.png'),
        link: "https://innerbeat.netlify.app/",
    },
]

export const gamesPortfolio = [
    {
        id: 11,
        title: 'Super Mario Word',
        img: require('../src/img/mario.png'),
        link: "https://mario-ashen.vercel.app/",
    },
    {
        id: 3,
        title: 'Tic Tac Toe',
        img: 'https://i.ibb.co/qRKV8SR/tic-tac-toe.png',
        link: "https://voluble-griffin-b75059.netlify.app/",
    },
    {
        id: 8,
        title: 'Trivia Game',
        img: 'https://i.ibb.co/1vBqg1J/trivia.png',
        link: "https://trivial-quiz.netlify.app/",
    },
    {
        id: 9,
        title: 'Paper Rock Scissor',
        img: 'https://i.ibb.co/jHsK4fb/rock-paper-scissor.png',
        link: "https://stoic-hermann-5cc792.netlify.app/",
    },
]

export const nextPortfolio = [
    {
        id: 16,
        title: 'Company Dashboard',
        img: require('../src/img/devchamoso.png'),
        link: "https://company-dashboard-kappa.vercel.app/",
    },
    {
        id: 15,
        title: 'Acqua Bank',
        img: require('../src/img/acqua.png'),
        link: "https://acqua-bank.vercel.app/",
    }
]

export const typescriptPortfolio = [
    {
        id: 16,
        title: 'Company Dashboard',
        img: require('../src/img/devchamoso.png'),
        link: "https://company-dashboard-kappa.vercel.app/",
    },
    {
        id: 15,
        title: 'Acqua Bank',
        img: require('../src/img/acqua.png'),
        link: "https://acqua-bank.vercel.app/",
    }
]
