import javascriptImg from '../../img/javascript.png';
import reactImg from '../../img/react.png'
import nextImg from '../../img/next.png';
import typescriptImg from '../../img/typescript.png';
import sassImg from '../../img/sass.png';
import phpImg from '../../img/php.png';
import jestImg from '../../img/jest.png';
import nodeImg from '../../img/node.png';
import firebaseImg from '../../img/firebase.jpg';
import mysqlImg from '../../img/mysql.png';
import vueImg from '../../img/vue.png';
import gsapImg from '../../img/gsap.jpg';
import outsystemsImg from '../../img/outsystems.png';
import html5Img from '../../img/html.png';
import css3Img from '../../img/css.png';
import gitImg from '../../img/git.png';
import tailwindImg from '../../img/tailwind.png';


export const skills = [
    { name: 'Javascript', img: javascriptImg },
    { name: 'React', img: reactImg },
    { name: 'Next', img: nextImg },
    { name: 'Typescript', img: typescriptImg },
    { name: 'Sass', img: sassImg },
    { name: 'PHP', img: phpImg },
    { name: 'JEST', img: jestImg },
    { name: 'Node', img: nodeImg },
    { name: 'Firebase', img: firebaseImg },
    { name: 'MySQL', img: mysqlImg },
    { name: 'Vue', img: vueImg },
    { name: 'GSAP', img: gsapImg },
    { name: 'Outsystems', img: outsystemsImg },
    { name: 'HTML5', img: html5Img },
    { name: 'CSS3', img: css3Img },
    { name: 'Git', img: gitImg },
    { name: 'Tailwind', img: tailwindImg },
  ];
  